import { Configuration } from '../Services/userAccess-sdk'

export class AppConfig {
  // static TOKEN_BASE_URL = 'http://localhost:5000/';
  // static APP_BASE_URL = 'http://localhost:5000';
  //static TOKEN_BASE_URL = 'https://bampos-api-dev.taptoweb.com/';
  //static APP_BASE_URL = 'https://bampos-api-dev.taptoweb.com';
  static TOKEN_BASE_URL = 'https://bampos-api-dev.taptoweb.com/'
  static APP_BASE_URL = 'https://test-api.bampos.com' 
  static CDN_URL = 'https://bampos-dev.taptoweb.com'
}

export const apiConfigFactory = new Configuration({
  basePath: AppConfig.APP_BASE_URL,
  apiKey: () => 'Bearer ',
  baseOptions: {
    headers: {
      //"X-BAMPOS-COMPANY-ID":''
    },
  },
})

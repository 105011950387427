

import Box from '@mui/material/Box'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {useLocation, useNavigate} from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined';
import React, { useEffect, useState } from 'react'

export const UserConfirmed = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const searchParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState<any|null>(null)


  useEffect(() => {
    const eMail = searchParams.get('email');
    setEmail(eMail)
    setIsLoading(false)
  }, [location.search]);

  
  if(isLoading) {
    return  (
    <div style={{alignItems:'center', justifyContent:'center', display:'flex', marginTop:100}}>
      <Box
        sx={{
          width:'100%',
          height: 280,
          marginBottom:11,
          padding:8,
          }}
        >
      </Box>
   </div>
   )
  }
  
  return (
    <>
    {email !== null ? (
      <div style={{alignItems:'center', justifyContent:'center', display:'flex', marginTop:100}}>
        <Box
        sx={{
          width:'100%',
          height: 280,
          marginBottom:11,
          padding:8,
          }}
        >
          <div>
            <ForwardToInboxIcon 
                style={{fontSize:80, color:'#6CC5F0'}}
            />
            <p style={{fontWeight:'bold', fontSize:35, padding:0, margin:0, marginTop:7}}>HESAP ONAYI</p>
            <p style={{marginBottom:50, fontSize:20}}>
              Hesap onay bağlantınızı içeren e-posta, <span style={{fontWeight:'bold'}}>{email}</span> adlı mail adresinize gönderildi.<br /><br />
              Lütfen kaydınızın tamamlanabilmesi için mail adresinize gelen bağlantıya tıklayınız.
            </p>
          </div>
        </Box>
       </div>
    ) : (

      <div style={{alignItems:'center', justifyContent:'center', display:'flex', marginTop:100}}>
      <Box
      sx={{
        width:'100%',
        height: 280,
        marginBottom:11,
        padding:8,
        }}
      >
        <div>
          <UnsubscribeOutlinedIcon 
              style={{fontSize:80, color:'red'}}
          />
          <p style={{fontWeight:'bold', fontSize:35, padding:0, margin:0, marginTop:7}}>KAYIT BULUNAMADI</p>
          <p style={{marginBottom:50, fontSize:20}}>
            Lütfen sağ yukarıdaki <span style={{color:'green', fontWeight:'bold', textDecoration:'underline'}}>'Kayıt Ol'</span> sekmesinden kayıt olunuz.
          </p>
        </div>
      </Box>
     </div>

    )}
    </>

  )
}


import { ScrollERP } from '../../components/ScrollERP'
import { ScrollCashier } from '../../components/ScrollCashier'
import { ScrollIntegration } from '../../components/ScrollIntegration'

export const Home = () => {
  return (
    <div style={{width:'100%'}}>
        <ScrollERP/>
        <ScrollCashier/>
        <ScrollIntegration/>
    </div>
  )
}



import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { BamPosNavBarIcon } from '../../Assets/icons/BampPosNavBar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CssBaseline from '@mui/material/CssBaseline';
import ErpMacImage from '../../Assets/images/mockup.png'
import ErpiPhoneImage from '../../Assets/images/mockupMobile.png'
import { ComputerIcon } from '../../Assets/icons/ComputerIcon';
import { Card, CardActions, CardContent } from '@mui/material';
import BamPosCardImage from '../../Assets/images/bampos-card.png'
import { AppStoreIcon } from '../../Assets/icons/AppStoreIcon';
import { IntegrationIcon } from '../../Assets/icons/IntegrationIcon';
import { makeStyles, TextField, Grid } from '@material-ui/core';
import { ArrowForward } from '@mui/icons-material';
import { ComputerCardIcon } from '../../Assets/icons/ComputerCardIcon';
import { RetailCardIcon } from '../../Assets/icons/RetailCardIcon';
import { ERPCardIcon } from '../../Assets/icons/ERPCardIcon';
import { DesignCardIcon } from '../../Assets/icons/DesignCardIcon';
import { FastCardIcon } from '../../Assets/icons/FastCardIcon';
import { IntegrationCardIcon } from '../../Assets/icons/IntegrationCardIcon';


const useStyles = makeStyles((theme) => ({
    container: {
        marginLeft: theme.spacing(40),
        marginRight: theme.spacing(40),
        marginTop: theme.spacing(10),
        
    },
    container2: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(10),
        
    },
    reportDiv: {
        background: "#FFFFFF",
        borderRadius: 10,
        padding: 20
    },
    valueSpan: {
        fontWeight: "bold",
        fontSize: 28
    },
    titleSpan: {
        fontWeight: 500,
        fontSize: 18,
        marginLeft: 15,
    },
    reportBtn: {
        borderRadius: 7,
        padding: "5px 20px",
        fontSize: 14,
    },
    titleDiv: {
        display: 'flex',
        alignItems: 'center'
    }
}));







export const IntegrationCard = () => {

    const classes = useStyles()


    return (
        <>
        <Box className={classes.container} sx={{display:{xs:'none', md:'flex'}}}>
        <Grid container spacing={4}>
            <Grid item xs={12} md={4} >
                <div className={classes.reportDiv} style={{background:'#00D1FF1A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                            <ComputerCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', textAlign:'start', marginTop: 25, color:'#00D1FF' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Mobil & Masaüstü Kolay Erişim</span>
                        </Grid>
                            
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#FFD6001A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <RetailCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', textAlign:'start', marginTop: 25, color:'#FFD600' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Perakendeye Uygun Stok Takibi</span>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#17D3621A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <ERPCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', textAlign:'start', marginTop: 25, color:'#17D362' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}> Kolay ERP Yönetim Sistemi</span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#D858D31A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <DesignCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex',  textAlign:'start', marginTop: 25,color:'#D858D3' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Kullanıcı Odaklı Deneyim Tasarımı</span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#E86F3B1A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <FastCardIcon/>
                        </div>


                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex',  textAlign:'start', marginTop: 25, color:'#E86F3B' }}>
                        <Grid item xs={12}>
                            <span className={classes.valueSpan}>Hızlı ve Çoklu Erişim Sistemi</span>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#466BEE1A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <IntegrationCardIcon/>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex',  textAlign:'start', marginTop: 25, color:'#466BEE' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Entegrasyonlar ile Basit Kurulum</span>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
        </Grid>
    </Box>

    <Box className={classes.container2} sx={{display:{xs:'flex', md:'none'}}}>
        <Grid container spacing={4}>
            <Grid item xs={12} md={4} >
                <div className={classes.reportDiv} style={{background:'#00D1FF1A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                            <ComputerCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', textAlign:'start', marginTop: 25, color:'#00D1FF' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Mobil & Masaüstü Kolay Erişim</span>
                        </Grid>
                            
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#FFD6001A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <RetailCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', textAlign:'start', marginTop: 25, color:'#FFD600' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Perakendeye Uygun Stok Takibi</span>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#17D3621A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <ERPCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', textAlign:'start', marginTop: 25, color:'#17D362' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}> Kolay ERP Yönetim Sistemi</span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#D858D31A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <DesignCardIcon/>
                        </div>

                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex',  textAlign:'start', marginTop: 25,color:'#D858D3' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Kullanıcı Odaklı Deneyim Tasarımı</span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#E86F3B1A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <FastCardIcon/>
                        </div>


                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex',  textAlign:'start', marginTop: 25, color:'#E86F3B' }}>
                        <Grid item xs={12}>
                            <span className={classes.valueSpan}>Hızlı ve Çoklu Erişim Sistemi</span>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={4}>
                <div className={classes.reportDiv} style={{background:'#466BEE1A'}}>
                    <Grid item xs={12}>
                        <div className={classes.titleDiv}>
                        <IntegrationCardIcon/>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex',  textAlign:'start', marginTop: 25, color:'#466BEE' }}>
                        <Grid item xs={10}>
                            <span className={classes.valueSpan}>Entegrasyonlar ile Basit Kurulum</span>
                        </Grid>

                    </Grid>
                </div>
            </Grid>
        </Grid>
    </Box>
    </>
    )
}

import React, { useEffect } from 'react'
import { Modal, makeStyles } from '@material-ui/core'
import { Link, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardActions, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form } from 'react-final-form';
import { AboutPage } from '../../pages/About';
import { BamPosFooterIcon } from '../../Assets/icons/BamPosFooterIcon';
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
    display: 'flex',
  },
  mobileFooter: {
        alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  baseFooter: {
    padding: 15,
    fontWeight: 500,
    fontSize: 20,
  },
  socialMediaFooter: {
    padding: 15,
    fontWeight: 500,
    fontSize: 20,
  },
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '24px',
    p: 4,
  },
  card: {
    boxShadow: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    paddingTop: theme.spacing(6),
  },
  actionButton: {


  },
  error: {
    color: theme.palette.error.main,
  },

}))

const socialMediaFooter = ['Twitter', 'Instagram', 'Facebook']
const socialMediaFooterLinks = ['www.twitter.com', 'Instagram', 'Facebook']
const baseFooter = ['Şartlar', 'Sözleşmeler', 'İletişim', 'Hakkında']
const baseFooterLinks = ['/', '/', '/', '/about'];


export const Footer = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [cancellationReason, setcancellationReason] = React.useState({});
  const [nameSurname, setNameSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [checkboxChecked, setCheckboxChecked] = React.useState(false);
  const [touched, setTouched] = React.useState({
    nameSurname: false,
    email: false,
    checkbox: false,
  });

  const handleBlur = (field: any) => {
    setTouched((prevTouched: any) => ({
      ...prevTouched,
      [field]: true,
    }));
  };

  const handleChange = (e: any, field: any) => {
    if (field === "nameSurname") {
      setNameSurname(e.target.value);
    } else if (field === "email") {
      setEmail(e.target.value);
    } else if (field === "checkbox") {
      setCheckboxChecked(e.target.checked);
    }
    setTouched((prevTouched: any) => ({
      ...prevTouched,
      [field]: false,
    }));
  };

  const handleClose = () => { setOpen(false) }

  const handleLinkClick = (index: number) => {
    if (index === 2) {

      setOpen(true);
    } else if (index === 3) {
      debugger
      setOpen(false);
      navigate(baseFooterLinks[index]);
    }
  };


  const onCancelBtn = (values: any) => {
    values.id = 'aaa';
  };
  
  return (
    <>
    <div>
      <Box className={classes.container} sx={{display:{xs:'none', md:'block'}}}>
        <div className={classes.footer}>
          {baseFooter.map((footer, index) => (

            <div className={classes.baseFooter} key={index}>
              <Link to={baseFooterLinks[index]}
                onClick={() => handleLinkClick(index)}
                style={{ textDecoration: 'none', color: '#000000' }}
              >{footer}
              </Link>
            </div>

          ))}
        </div>
        <div className={classes.footer}>
          {socialMediaFooter.map((footer, index) => (
            <footer key={index} className={classes.socialMediaFooter}>
              {footer}
            </footer>
          ))}

        </div>
        <div className={classes.footer} style={{marginTop:20}}>
          <BamPosFooterIcon />
        </div>
        <div className={classes.footer}>
          <p>© Copyright 2022 - Tüm hakları saklıdır.</p>
        </div>
      </Box>

      <Box className={classes.mobileFooter} sx={{display:{xs:'block', md:'none'}}}>
        <div className={classes.mobileFooter}>
          {baseFooter.map((footer, index) => (

            <div className={classes.baseFooter} key={index}>
              <Link to={baseFooterLinks[index]}
                onClick={() => handleLinkClick(index)}
                style={{ textDecoration: 'none', color: '#000000' }}
              >{footer}
              </Link>
            </div>

          ))}
        </div>
        <div className={classes.mobileFooter} style={{marginTop:35}}>
          {socialMediaFooter.map((footer, index) => (
            <footer key={index} className={classes.socialMediaFooter }   style={{ textDecoration: 'none', color: '#000000B2' }}>
              {footer}
            </footer>
          ))}

        </div>
        <div className={classes.footer} style={{marginTop:20}}>
          <BamPosFooterIcon />
        </div>
        <div className={classes.footer}>
          <p>© Copyright 2022 - Tüm hakları saklıdır.</p>
        </div>
      </Box>

      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin:25,
        }}
        aria-describedby="simple-modal-description"
      >
        <Card className={classes.paper} style={{ position: 'relative', borderRadius: 15 }}>
          <Form
            onSubmit={(value: any) => onCancelBtn(value)}
            initialValues={cancellationReason}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <CardContent>
                  <IconButton style={{ position: 'absolute', top: 15, right:15 }} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                  <div style={{ textAlign: "center" }}>
                    <h2 style={{ marginTop: 0 }}>
                    </h2>
                  </div>
                  <div style={{ width: "100%" }}>
                    <TextField
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 20 }}
                      label="Ad ve Soyad"
                      required
                      value={nameSurname}
                      onChange={(e) => handleChange(e, "nameSurname")}
                      onBlur={() => handleBlur("nameSurname")}
                      helperText={!nameSurname && touched.nameSurname ? "Zorunlu alan" : ""}
                      error={!nameSurname && touched.nameSurname}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 20 }}
                      label="E-Posta Adresi"
                      required
                      value={email}
                      onChange={(e) => handleChange(e, "email")}
                      onBlur={() => handleBlur("email")}
                      helperText={!email && touched.email ? "Zorunlu alan" : ""}
                      error={!email && touched.email}
                    ></TextField>
                    <TextField
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 20 }}
                      label="Şirket Adı"
                    ></TextField>
                    <TextField
                      variant="outlined"
                      style={{ width: "100%", marginBottom: 20 }}
                      label="Web Site URL"
                    ></TextField>
                    


                  </div>
                  <p style={{color:'red', width:'100%'}}>* Bu Alanlar Zorunludur</p>
                  <FormGroup style={{color:'#808494', width: "100%"}}>
                    <FormControlLabel control={<Checkbox />} label="Şartlar ve Gizlilik Politikasını okuduğumu, anladığımı  ve kabul ettiğimi onaylıyorum.*" />
                  </FormGroup>
                </CardContent>
                <CardActions>
                  <Grid
                    spacing={2}
                    alignItems="center"
                    alignContent="center"
                    container
                  >
                    <Grid item xs className={classes.actionButton}>
                      <Button
                        size="small"
                        variant="contained"
                        style={{ width: "100%", height: '70px', borderRadius: '7px', fontSize: 18, background: '#00D1FF', color: '#FFFFFF' }}
                        color="inherit"
                        type="submit"

                        disableElevation
                      >
                        Gönder
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </form>
            )}
          />
        </Card>
      </Modal>
      </>
    
  )
}

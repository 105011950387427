/* tslint:disable */
/* eslint-disable */
/**
 * BamPos User Access API
 * BamPos API for User Access module
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthorizationDetailDto
 */
export interface AuthorizationDetailDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationDetailDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationDetailDto
     */
    'authorizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationDetailDto
     */
    'parentAuthorizationId'?: string | null;
}
/**
 * 
 * @export
 * @interface AuthorizationRequest
 */
export interface AuthorizationRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationRequest
     */
    'authorizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationRequest
     */
    'parentAuthorizationId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordRequest
 */
export interface ChangePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordRequest
     */
    'passwordAgain'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserAuthorizationCommand
 */
export interface CreateUserAuthorizationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateUserAuthorizationCommand
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserAuthorizationCommand
     */
    'authorizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserAuthorizationCommand
     */
    'status'?: string | null;
    /**
     * 
     * @type {Array<CreateUserAuthorizationCommand>}
     * @memberof CreateUserAuthorizationCommand
     */
    'userAuthorizationList'?: Array<CreateUserAuthorizationCommand> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserAuthorizationCommand
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface ManagementUserDetailDto
 */
export interface ManagementUserDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDetailDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDetailDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDetailDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDetailDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDetailDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementUserDetailDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ManagementUserDto
 */
export interface ManagementUserDto {
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementUserDto
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ManagementUserDtoPaging
 */
export interface ManagementUserDtoPaging {
    /**
     * 
     * @type {number}
     * @memberof ManagementUserDtoPaging
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ManagementUserDto>}
     * @memberof ManagementUserDtoPaging
     */
    'data'?: Array<ManagementUserDto> | null;
}
/**
 * 
 * @export
 * @interface ManagementUserRequest
 */
export interface ManagementUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ManagementUserRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserRequest
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManagementUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManagementUserRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface RegisterNewUserRequest
 */
export interface RegisterNewUserRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRequest
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRequest
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRequest
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterNewUserRequest
     */
    'confirmLink'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAuthorizationDetailDto
 */
export interface UserAuthorizationDetailDto {
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationDetailDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationDetailDto
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationDetailDto
     */
    'authorizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationDetailDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {AuthorizationDetailDto}
     * @memberof UserAuthorizationDetailDto
     */
    'authorization'?: AuthorizationDetailDto | null;
}
/**
 * 
 * @export
 * @interface UserAuthorizationRequest
 */
export interface UserAuthorizationRequest {
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationRequest
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationRequest
     */
    'authorizationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorizationRequest
     */
    'status'?: string | null;
    /**
     * 
     * @type {Array<CreateUserAuthorizationCommand>}
     * @memberof UserAuthorizationRequest
     */
    'userAuthorizationList'?: Array<CreateUserAuthorizationCommand> | null;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'login'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'companyAddressId'?: string;
}

/**
 * AuthenticatedUserApi - axios parameter creator
 * @export
 */
export const AuthenticatedUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthenticatedUserCompanyusersGet: async (page?: number, pageSize?: number, orderBy?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/authenticatedUser/companyusers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthenticatedUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/authenticatedUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthenticatedUserPermissionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/authenticatedUser/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticatedUserApi - functional programming interface
 * @export
 */
export const AuthenticatedUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticatedUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthenticatedUserCompanyusersGet(page?: number, pageSize?: number, orderBy?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAuthorizationDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthenticatedUserCompanyusersGet(page, pageSize, orderBy, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthenticatedUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthenticatedUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthenticatedUserPermissionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAuthorizationDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthenticatedUserPermissionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticatedUserApi - factory interface
 * @export
 */
export const AuthenticatedUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticatedUserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthenticatedUserCompanyusersGet(page?: number, pageSize?: number, orderBy?: string, filter?: string, options?: any): AxiosPromise<Array<UserAuthorizationDetailDto>> {
            return localVarFp.apiUserAccessAuthenticatedUserCompanyusersGet(page, pageSize, orderBy, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthenticatedUserGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserAccessAuthenticatedUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthenticatedUserPermissionsGet(options?: any): AxiosPromise<Array<UserAuthorizationDetailDto>> {
            return localVarFp.apiUserAccessAuthenticatedUserPermissionsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticatedUserApi - object-oriented interface
 * @export
 * @class AuthenticatedUserApi
 * @extends {BaseAPI}
 */
export class AuthenticatedUserApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderBy] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedUserApi
     */
    public apiUserAccessAuthenticatedUserCompanyusersGet(page?: number, pageSize?: number, orderBy?: string, filter?: string, options?: AxiosRequestConfig) {
        return AuthenticatedUserApiFp(this.configuration).apiUserAccessAuthenticatedUserCompanyusersGet(page, pageSize, orderBy, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedUserApi
     */
    public apiUserAccessAuthenticatedUserGet(options?: AxiosRequestConfig) {
        return AuthenticatedUserApiFp(this.configuration).apiUserAccessAuthenticatedUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticatedUserApi
     */
    public apiUserAccessAuthenticatedUserPermissionsGet(options?: AxiosRequestConfig) {
        return AuthenticatedUserApiFp(this.configuration).apiUserAccessAuthenticatedUserPermissionsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserAccessAuthorizationIdGet', 'id', id)
            const localVarPath = `/api/userAccess/Authorization/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorizationRequest} [authorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationPost: async (authorizationRequest?: AuthorizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorizationRequest} [authorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationPut: async (authorizationRequest?: AuthorizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization/UserAuthorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationDetailIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserAccessAuthorizationUserAuthorizationDetailIdGet', 'id', id)
            const localVarPath = `/api/userAccess/Authorization/UserAuthorizationDetail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationPost: async (userAuthorizationRequest?: UserAuthorizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization/UserAuthorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAuthorizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationPut: async (userAuthorizationRequest?: UserAuthorizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/Authorization/UserAuthorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAuthorizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserAccessAuthorizationUserAuthorizationsIdGet', 'id', id)
            const localVarPath = `/api/userAccess/Authorization/UserAuthorizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthorizationDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorizationRequest} [authorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationPost(authorizationRequest?: AuthorizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationPost(authorizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorizationRequest} [authorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationPut(authorizationRequest?: AuthorizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationPut(authorizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationUserAuthorizationDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationUserAuthorizationDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationUserAuthorizationDetailIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthorizationDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationUserAuthorizationDetailIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationUserAuthorizationPost(userAuthorizationRequest?: UserAuthorizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationUserAuthorizationPost(userAuthorizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationUserAuthorizationPut(userAuthorizationRequest?: UserAuthorizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationUserAuthorizationPut(userAuthorizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessAuthorizationUserAuthorizationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAuthorizationDetailDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessAuthorizationUserAuthorizationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorizationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAccessAuthorizationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationGet(options?: any): AxiosPromise<Array<AuthorizationDetailDto>> {
            return localVarFp.apiUserAccessAuthorizationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationIdGet(id: string, options?: any): AxiosPromise<AuthorizationDetailDto> {
            return localVarFp.apiUserAccessAuthorizationIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorizationRequest} [authorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationPost(authorizationRequest?: AuthorizationRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserAccessAuthorizationPost(authorizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorizationRequest} [authorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationPut(authorizationRequest?: AuthorizationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAccessAuthorizationPut(authorizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationDelete(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAccessAuthorizationUserAuthorizationDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationDetailIdGet(id: string, options?: any): AxiosPromise<UserAuthorizationDetailDto> {
            return localVarFp.apiUserAccessAuthorizationUserAuthorizationDetailIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationPost(userAuthorizationRequest?: UserAuthorizationRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserAccessAuthorizationUserAuthorizationPost(userAuthorizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationPut(userAuthorizationRequest?: UserAuthorizationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAccessAuthorizationUserAuthorizationPut(userAuthorizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessAuthorizationUserAuthorizationsIdGet(id: string, options?: any): AxiosPromise<Array<UserAuthorizationDetailDto>> {
            return localVarFp.apiUserAccessAuthorizationUserAuthorizationsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationDelete(id?: string, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationGet(options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationIdGet(id: string, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorizationRequest} [authorizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationPost(authorizationRequest?: AuthorizationRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationPost(authorizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorizationRequest} [authorizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationPut(authorizationRequest?: AuthorizationRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationPut(authorizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationUserAuthorizationDelete(id?: string, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationUserAuthorizationDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationUserAuthorizationDetailIdGet(id: string, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationUserAuthorizationDetailIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationUserAuthorizationPost(userAuthorizationRequest?: UserAuthorizationRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationUserAuthorizationPost(userAuthorizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserAuthorizationRequest} [userAuthorizationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationUserAuthorizationPut(userAuthorizationRequest?: UserAuthorizationRequest, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationUserAuthorizationPut(userAuthorizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public apiUserAccessAuthorizationUserAuthorizationsIdGet(id: string, options?: AxiosRequestConfig) {
        return AuthorizationApiFp(this.configuration).apiUserAccessAuthorizationUserAuthorizationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<void> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmailsApi - axios parameter creator
 * @export
 */
export const EmailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessEmailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailsApi - functional programming interface
 * @export
 */
export const EmailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessEmailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessEmailsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailsApi - factory interface
 * @export
 */
export const EmailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessEmailsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAccessEmailsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailsApi - object-oriented interface
 * @export
 * @class EmailsApi
 * @extends {BaseAPI}
 */
export class EmailsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailsApi
     */
    public apiUserAccessEmailsGet(options?: AxiosRequestConfig) {
        return EmailsApiFp(this.configuration).apiUserAccessEmailsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagementUserApi - axios parameter creator
 * @export
 */
export const ManagementUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserGet: async (page?: number, pageSize?: number, orderBy?: string, filter?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/ManagementUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserAccessManagementUserIdGet', 'id', id)
            const localVarPath = `/api/userAccess/ManagementUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagementUserRequest} [managementUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserPost: async (managementUserRequest?: ManagementUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/ManagementUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManagementUserRequest} [managementUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserPut: async (managementUserRequest?: ManagementUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/userAccess/ManagementUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(managementUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagementUserApi - functional programming interface
 * @export
 */
export const ManagementUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagementUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessManagementUserGet(page?: number, pageSize?: number, orderBy?: string, filter?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagementUserDtoPaging>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessManagementUserGet(page, pageSize, orderBy, filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessManagementUserIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManagementUserDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessManagementUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManagementUserRequest} [managementUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessManagementUserPost(managementUserRequest?: ManagementUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessManagementUserPost(managementUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManagementUserRequest} [managementUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAccessManagementUserPut(managementUserRequest?: ManagementUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAccessManagementUserPut(managementUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagementUserApi - factory interface
 * @export
 */
export const ManagementUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagementUserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserGet(page?: number, pageSize?: number, orderBy?: string, filter?: string, options?: any): AxiosPromise<ManagementUserDtoPaging> {
            return localVarFp.apiUserAccessManagementUserGet(page, pageSize, orderBy, filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserIdGet(id: string, options?: any): AxiosPromise<ManagementUserDetailDto> {
            return localVarFp.apiUserAccessManagementUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagementUserRequest} [managementUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserPost(managementUserRequest?: ManagementUserRequest, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserAccessManagementUserPost(managementUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManagementUserRequest} [managementUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAccessManagementUserPut(managementUserRequest?: ManagementUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserAccessManagementUserPut(managementUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagementUserApi - object-oriented interface
 * @export
 * @class ManagementUserApi
 * @extends {BaseAPI}
 */
export class ManagementUserApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [orderBy] 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementUserApi
     */
    public apiUserAccessManagementUserGet(page?: number, pageSize?: number, orderBy?: string, filter?: string, options?: AxiosRequestConfig) {
        return ManagementUserApiFp(this.configuration).apiUserAccessManagementUserGet(page, pageSize, orderBy, filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementUserApi
     */
    public apiUserAccessManagementUserIdGet(id: string, options?: AxiosRequestConfig) {
        return ManagementUserApiFp(this.configuration).apiUserAccessManagementUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagementUserRequest} [managementUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementUserApi
     */
    public apiUserAccessManagementUserPost(managementUserRequest?: ManagementUserRequest, options?: AxiosRequestConfig) {
        return ManagementUserApiFp(this.configuration).apiUserAccessManagementUserPost(managementUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManagementUserRequest} [managementUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementUserApi
     */
    public apiUserAccessManagementUserPut(managementUserRequest?: ManagementUserRequest, options?: AxiosRequestConfig) {
        return ManagementUserApiFp(this.configuration).apiUserAccessManagementUserPut(managementUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRegistrationsApi - axios parameter creator
 * @export
 */
export const UserRegistrationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccessUserRegistrationsChangePasswordPut: async (changePasswordRequest?: ChangePasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/userAccess/UserRegistrations/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterNewUserRequest} [registerNewUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccessUserRegistrationsPost: async (registerNewUserRequest?: RegisterNewUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/userAccess/UserRegistrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerNewUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userRegistrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccessUserRegistrationsUserRegistrationIdConfirmPatch: async (userRegistrationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegistrationId' is not null or undefined
            assertParamExists('userAccessUserRegistrationsUserRegistrationIdConfirmPatch', 'userRegistrationId', userRegistrationId)
            const localVarPath = `/userAccess/UserRegistrations/{userRegistrationId}/confirm`
                .replace(`{${"userRegistrationId"}}`, encodeURIComponent(String(userRegistrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRegistrationsApi - functional programming interface
 * @export
 */
export const UserRegistrationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRegistrationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAccessUserRegistrationsChangePasswordPut(changePasswordRequest?: ChangePasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAccessUserRegistrationsChangePasswordPut(changePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterNewUserRequest} [registerNewUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAccessUserRegistrationsPost(registerNewUserRequest?: RegisterNewUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAccessUserRegistrationsPost(registerNewUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userRegistrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAccessUserRegistrationsUserRegistrationIdConfirmPatch(userRegistrationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAccessUserRegistrationsUserRegistrationIdConfirmPatch(userRegistrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRegistrationsApi - factory interface
 * @export
 */
export const UserRegistrationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRegistrationsApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePasswordRequest} [changePasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccessUserRegistrationsChangePasswordPut(changePasswordRequest?: ChangePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userAccessUserRegistrationsChangePasswordPut(changePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterNewUserRequest} [registerNewUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccessUserRegistrationsPost(registerNewUserRequest?: RegisterNewUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userAccessUserRegistrationsPost(registerNewUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userRegistrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAccessUserRegistrationsUserRegistrationIdConfirmPatch(userRegistrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAccessUserRegistrationsUserRegistrationIdConfirmPatch(userRegistrationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRegistrationsApi - object-oriented interface
 * @export
 * @class UserRegistrationsApi
 * @extends {BaseAPI}
 */
export class UserRegistrationsApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordRequest} [changePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegistrationsApi
     */
    public userAccessUserRegistrationsChangePasswordPut(changePasswordRequest?: ChangePasswordRequest, options?: AxiosRequestConfig) {
        return UserRegistrationsApiFp(this.configuration).userAccessUserRegistrationsChangePasswordPut(changePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterNewUserRequest} [registerNewUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegistrationsApi
     */
    public userAccessUserRegistrationsPost(registerNewUserRequest?: RegisterNewUserRequest, options?: AxiosRequestConfig) {
        return UserRegistrationsApiFp(this.configuration).userAccessUserRegistrationsPost(registerNewUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userRegistrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRegistrationsApi
     */
    public userAccessUserRegistrationsUserRegistrationIdConfirmPatch(userRegistrationId: string, options?: AxiosRequestConfig) {
        return UserRegistrationsApiFp(this.configuration).userAccessUserRegistrationsUserRegistrationIdConfirmPatch(userRegistrationId, options).then((request) => request(this.axios, this.basePath));
    }
}




export const AppStoreIcon = () => {
    return <svg width="132" height="44" viewBox="0 0 132 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_781_326)">
<path d="M121.148 2.09297e-05H10.4881C10.0848 2.09297e-05 9.68625 2.09297e-05 9.28395 0.00222093C8.94718 0.00442093 8.6131 0.0108119 8.2731 0.0161909C7.53447 0.0248829 6.79757 0.0898648 6.06881 0.210572C5.34108 0.333907 4.63614 0.566428 3.97784 0.900272C3.32035 1.23694 2.71958 1.67441 2.19733 2.1968C1.67233 2.71772 1.23471 3.31984 0.901285 3.98C0.56694 4.63884 0.335105 5.34485 0.213785 6.07365C0.091312 6.80152 0.0254082 7.53779 0.016665 8.27585C0.006457 8.61311 0.005379 8.95149 0 9.28879V34.7145C0.005379 35.056 0.006457 35.3869 0.016665 35.7286C0.0254109 36.4666 0.0913147 37.2028 0.213785 37.9307C0.33477 38.6599 0.566619 39.3663 0.901285 40.0254C1.23456 40.6834 1.67224 41.2831 2.19733 41.8011C2.7176 42.3258 3.31876 42.7636 3.97784 43.0977C4.63614 43.4324 5.341 43.6663 6.06881 43.7916C6.79769 43.9113 7.53451 43.9763 8.2731 43.986C8.6131 43.9935 8.94718 43.9978 9.28395 43.9978C9.68624 44 10.0848 44 10.4881 44H121.148C121.544 44 121.945 44 122.341 43.9978C122.676 43.9978 123.02 43.9935 123.355 43.986C124.092 43.9768 124.827 43.9118 125.555 43.7916C126.285 43.6654 126.992 43.4316 127.654 43.0977C128.312 42.7634 128.913 42.3256 129.433 41.8011C129.956 41.2811 130.395 40.6818 130.732 40.0254C131.065 39.3658 131.294 38.6595 131.413 37.9307C131.536 37.2027 131.604 36.4666 131.618 35.7286C131.622 35.3869 131.622 35.056 131.622 34.7145C131.63 34.3148 131.63 33.9174 131.63 33.5113V10.4898C131.63 10.0869 131.63 9.68732 131.622 9.28879C131.622 8.95149 131.622 8.61311 131.618 8.2758C131.604 7.53768 131.536 6.80158 131.413 6.0736C131.294 5.34523 131.064 4.63927 130.732 3.97995C130.053 2.65674 128.977 1.57965 127.654 0.900173C126.992 0.567145 126.285 0.334685 125.555 0.210473C124.827 0.089234 124.092 0.0242295 123.355 0.0160369C123.02 0.0106689 122.676 0.00422293 122.341 0.00207793C121.945 -0.00012207 121.544 -0.00012207 121.148 -0.00012207V2.09297e-05Z" fill="#A6A6A6"/>
<path d="M9.2893 43.0375C8.95415 43.0375 8.6271 43.0332 8.29458 43.0258C7.60574 43.0168 6.91851 42.9568 6.23853 42.8463C5.60448 42.7371 4.99025 42.534 4.41611 42.2437C3.84724 41.9557 3.32838 41.5781 2.87941 41.1254C2.42395 40.678 2.04475 40.1591 1.75685 39.5892C1.46578 39.0157 1.26434 38.4009 1.15955 37.7663C1.04639 37.0845 0.985161 36.395 0.976401 35.7038C0.969427 35.4718 0.960297 34.6994 0.960297 34.6994V9.2888C0.960297 9.2888 0.970021 8.52825 0.976456 8.3048C0.984844 7.61475 1.04571 6.92634 1.15854 6.24553C1.26352 5.6092 1.46512 4.99265 1.75634 4.41722C2.04319 3.84776 2.42028 3.32847 2.873 2.87947C3.32521 2.42621 3.84573 2.04669 4.41557 1.75476C4.98839 1.46533 5.60146 1.26363 6.23422 1.15642C6.91644 1.04484 7.60606 0.98452 8.29729 0.975955L9.28984 0.962524H122.335L123.34 0.976494C124.025 0.984634 124.708 1.04442 125.384 1.15534C126.023 1.2639 126.643 1.467 127.222 1.75798C128.363 2.34631 129.292 3.2771 129.878 4.4199C130.165 4.99136 130.363 5.60288 130.467 6.23371C130.581 6.92011 130.645 7.61394 130.658 8.30964C130.661 8.62116 130.661 8.95578 130.661 9.2888C130.67 9.7013 130.67 10.0939 130.67 10.4898V33.5113C130.67 33.9109 130.67 34.3009 130.661 34.694C130.661 35.0517 130.661 35.3794 130.657 35.7167C130.644 36.4 130.582 37.0814 130.469 37.7555C130.367 38.3947 130.166 39.0143 129.875 39.5925C129.585 40.1562 129.208 40.6706 128.758 41.1168C128.309 41.572 127.789 41.9518 127.219 42.2416C126.641 42.5342 126.022 42.738 125.384 42.8463C124.704 42.9574 124.017 43.0174 123.328 43.0258C123.006 43.0332 122.668 43.0375 122.341 43.0375L121.148 43.0397L9.2893 43.0375Z" fill="black"/>
<path d="M27.2458 22.3307C27.2576 21.4126 27.5015 20.5123 27.9547 19.7138C28.4079 18.9152 29.0558 18.2443 29.838 17.7634C29.3411 17.0537 28.6855 16.4697 27.9234 16.0577C27.1613 15.6457 26.3136 15.4171 25.4477 15.39C23.6005 15.1961 21.8098 16.4953 20.8685 16.4953C19.909 16.4953 18.4598 15.4092 16.8992 15.4413C15.8897 15.4739 14.9059 15.7675 14.0437 16.2933C13.1814 16.8192 12.4701 17.5595 11.979 18.442C9.85155 22.1252 11.4384 27.5384 13.4763 30.5157C14.4959 31.9736 15.6875 33.602 17.2467 33.5443C18.7724 33.481 19.3423 32.5714 21.184 32.5714C23.0086 32.5714 23.5433 33.5443 25.1341 33.5076C26.7713 33.481 27.8028 32.0432 28.7867 30.5715C29.5192 29.5327 30.083 28.3846 30.4569 27.1698C29.5058 26.7675 28.694 26.0941 28.123 25.2335C27.552 24.373 27.2469 23.3634 27.2458 22.3307Z" fill="white"/>
<path d="M24.241 13.432C25.1337 12.3603 25.5734 10.9829 25.467 9.59229C24.1031 9.73553 22.8434 10.3873 21.9386 11.4179C21.4963 11.9213 21.1575 12.507 20.9416 13.1414C20.7257 13.7759 20.637 14.4466 20.6805 15.1154C21.3627 15.1224 22.0375 14.9746 22.6543 14.683C23.271 14.3914 23.8135 13.9636 24.241 13.432Z" fill="white"/>
<path d="M46.5325 29.8536H41.3258L40.0754 33.5457H37.87L42.8017 19.8859H45.093L50.0248 33.5457H47.7818L46.5325 29.8536ZM41.865 28.1499H45.9922L43.9576 22.1579H43.9007L41.865 28.1499Z" fill="white"/>
<path d="M60.6756 28.5666C60.6756 31.6614 59.0192 33.6498 56.5195 33.6498C55.8863 33.6829 55.2565 33.5371 54.7024 33.2289C54.1482 32.9207 53.692 32.4628 53.386 31.9074H53.3387V36.8402H51.2945V23.5865H53.2732V25.243H53.3108C53.6309 24.6902 54.0948 24.2346 54.6532 23.9246C55.2115 23.6146 55.8435 23.4617 56.4819 23.4823C59.0095 23.4823 60.6756 25.4804 60.6756 28.5666ZM58.5745 28.5666C58.5745 26.5503 57.5325 25.2247 55.9426 25.2247C54.3807 25.2247 53.3301 26.5782 53.3301 28.5666C53.3301 30.5732 54.3807 31.9171 55.9426 31.9171C57.5325 31.9171 58.5745 30.6012 58.5745 28.5666Z" fill="white"/>
<path d="M71.637 28.5666C71.637 31.6614 69.9805 33.6498 67.4808 33.6498C66.8476 33.6829 66.2179 33.537 65.6637 33.2289C65.1095 32.9207 64.6533 32.4628 64.3473 31.9074H64.3V36.8402H62.2558V23.5865H64.2345V25.2429H64.2721C64.5922 24.6902 65.0561 24.2346 65.6144 23.9246C66.1728 23.6146 66.8048 23.4617 67.4432 23.4823C69.9709 23.4823 71.637 25.4803 71.637 28.5666ZM69.5358 28.5666C69.5358 26.5503 68.4938 25.2247 66.904 25.2247C65.3421 25.2247 64.2915 26.5782 64.2915 28.5666C64.2915 30.5732 65.3421 31.9171 66.904 31.9171C68.4938 31.9171 69.5358 30.6012 69.5358 28.5666H69.5358Z" fill="white"/>
<path d="M78.8815 29.7397C79.033 31.0943 80.3489 31.9837 82.1471 31.9837C83.8702 31.9837 85.1098 31.0942 85.1098 29.8728C85.1098 28.8126 84.3622 28.1777 82.5919 27.7427L80.8216 27.3162C78.3133 26.7103 77.1488 25.5373 77.1488 23.6338C77.1488 21.2769 79.2027 19.6581 82.1192 19.6581C85.0056 19.6581 86.9844 21.2769 87.051 23.6338H84.9874C84.8638 22.2706 83.737 21.4477 82.0902 21.4477C80.4434 21.4477 79.3166 22.2803 79.3166 23.492C79.3166 24.4577 80.0363 25.026 81.7969 25.461L83.3019 25.8305C86.1045 26.4933 87.269 27.6191 87.269 29.6171C87.269 32.1727 85.2333 33.7733 81.9956 33.7733C78.9663 33.7733 76.921 32.2103 76.7889 29.7396L78.8815 29.7397Z" fill="white"/>
<path d="M91.6808 21.2297V23.5866H93.5747V25.2054H91.6808V30.6958C91.6808 31.5487 92.06 31.9461 92.8925 31.9461C93.1174 31.9422 93.3418 31.9264 93.565 31.8988V33.508C93.1907 33.578 92.8102 33.6096 92.4295 33.6026C90.4132 33.6026 89.6269 32.8452 89.6269 30.9138V25.2054H88.1789V23.5866H89.6269V21.2297H91.6808Z" fill="white"/>
<path d="M94.6715 28.5666C94.6715 25.4332 96.517 23.4641 99.3948 23.4641C102.282 23.4641 104.119 25.4331 104.119 28.5666C104.119 31.7087 102.292 33.6692 99.3948 33.6692C96.4987 33.6692 94.6715 31.7087 94.6715 28.5666ZM102.036 28.5666C102.036 26.4171 101.051 25.1485 99.3948 25.1485C97.7384 25.1485 96.7544 26.4268 96.7544 28.5666C96.7544 30.7247 97.7384 31.9837 99.3948 31.9837C101.051 31.9837 102.036 30.7247 102.036 28.5666H102.036Z" fill="white"/>
<path d="M105.805 23.5865H107.754V25.2816H107.802C107.934 24.7522 108.244 24.2844 108.68 23.9567C109.116 23.629 109.652 23.4615 110.197 23.4823C110.433 23.4815 110.668 23.5071 110.898 23.5586V25.4707C110.6 25.3798 110.29 25.3381 109.979 25.3472C109.682 25.3351 109.386 25.3875 109.111 25.5007C108.836 25.6138 108.589 25.7851 108.387 26.0028C108.185 26.2205 108.032 26.4794 107.939 26.7617C107.846 27.0441 107.815 27.3432 107.849 27.6385V33.5456H105.805L105.805 23.5865Z" fill="white"/>
<path d="M120.323 30.6206C120.048 32.4285 118.287 33.6692 116.034 33.6692C113.137 33.6692 111.339 31.7281 111.339 28.6139C111.339 25.4901 113.147 23.4641 115.949 23.4641C118.704 23.4641 120.437 25.3569 120.437 28.3765V29.0769H113.403V29.2004C113.37 29.567 113.416 29.9362 113.537 30.2836C113.658 30.631 113.852 30.9487 114.106 31.2155C114.359 31.4823 114.666 31.6921 115.007 31.8311C115.348 31.97 115.714 32.0349 116.082 32.0213C116.565 32.0666 117.05 31.9547 117.464 31.7023C117.878 31.4499 118.2 31.0705 118.382 30.6205L120.323 30.6206ZM113.412 27.6482H118.391C118.41 27.3186 118.36 26.9888 118.245 26.6795C118.129 26.3702 117.952 26.088 117.722 25.8506C117.493 25.6133 117.217 25.4259 116.912 25.3003C116.606 25.1747 116.279 25.1135 115.949 25.1206C115.616 25.1186 115.286 25.1825 114.978 25.3087C114.67 25.435 114.39 25.6209 114.154 25.8559C113.918 26.0909 113.731 26.3703 113.604 26.6779C113.477 26.9855 113.411 27.3153 113.412 27.6482V27.6482Z" fill="white"/>
<path d="M41.6088 9.60413C42.0374 9.57337 42.4674 9.63812 42.868 9.79369C43.2685 9.94927 43.6295 10.1918 43.9249 10.5038C44.2204 10.8158 44.4429 11.1894 44.5764 11.5978C44.71 12.0062 44.7512 12.4392 44.6972 12.8655C44.6972 14.9623 43.5639 16.1677 41.6088 16.1677H39.238V9.60413H41.6088ZM40.2574 15.2394H41.4949C41.8012 15.2577 42.1076 15.2075 42.392 15.0924C42.6764 14.9774 42.9316 14.8003 43.1389 14.5742C43.3463 14.3481 43.5006 14.0786 43.5906 13.7853C43.6807 13.492 43.7042 13.1823 43.6595 12.8788C43.701 12.5765 43.6751 12.2688 43.5836 11.9776C43.4922 11.6865 43.3375 11.4192 43.1306 11.1949C42.9238 10.9706 42.6698 10.7948 42.387 10.6801C42.1043 10.5654 41.7996 10.5148 41.4949 10.5317H40.2574V15.2394Z" fill="white"/>
<path d="M45.8487 13.6888C45.8176 13.3633 45.8548 13.0349 45.958 12.7246C46.0613 12.4143 46.2282 12.129 46.4481 11.887C46.6681 11.6451 46.9362 11.4517 47.2352 11.3194C47.5342 11.1871 47.8576 11.1188 48.1846 11.1188C48.5116 11.1188 48.835 11.1871 49.134 11.3194C49.4331 11.4517 49.7012 11.6451 49.9211 11.887C50.141 12.129 50.308 12.4143 50.4112 12.7246C50.5144 13.0349 50.5516 13.3633 50.5205 13.6888C50.5522 14.0146 50.5154 14.3435 50.4125 14.6543C50.3095 14.9651 50.1427 15.2509 49.9227 15.4933C49.7027 15.7358 49.4344 15.9295 49.1351 16.0621C48.8358 16.1947 48.512 16.2632 48.1846 16.2632C47.8572 16.2632 47.5335 16.1947 47.2341 16.0621C46.9348 15.9295 46.6665 15.7358 46.4465 15.4933C46.2265 15.2509 46.0597 14.9651 45.9567 14.6543C45.8538 14.3435 45.817 14.0146 45.8487 13.6888ZM49.515 13.6888C49.515 12.6151 49.0327 11.9872 48.1862 11.9872C47.3365 11.9872 46.8585 12.6151 46.8585 13.6888C46.8585 14.7711 47.3366 15.3941 48.1862 15.3941C49.0327 15.3941 49.515 14.7668 49.515 13.6888H49.515Z" fill="white"/>
<path d="M56.7306 16.1676H55.7165L54.6928 12.5195H54.6155L53.596 16.1676H52.5916L51.2263 11.2144H52.2178L53.1051 14.994H53.1782L54.1965 11.2144H55.1343L56.1527 14.994H56.23L57.113 11.2144H58.0905L56.7306 16.1676Z" fill="white"/>
<path d="M59.2389 11.2144H60.1799V12.0012H60.253C60.3769 11.7186 60.5859 11.4817 60.8508 11.3235C61.1158 11.1653 61.4235 11.0938 61.7311 11.1188C61.9721 11.1006 62.2141 11.137 62.4391 11.2251C62.6642 11.3132 62.8666 11.4508 63.0312 11.6277C63.1959 11.8047 63.3186 12.0164 63.3903 12.2472C63.462 12.478 63.4809 12.722 63.4455 12.961V16.1675H62.468V13.2065C62.468 12.4105 62.1221 12.0147 61.3991 12.0147C61.2355 12.0071 61.0722 12.0349 60.9203 12.0963C60.7684 12.1577 60.6317 12.2513 60.5193 12.3705C60.407 12.4897 60.3218 12.6318 60.2695 12.787C60.2172 12.9423 60.1991 13.107 60.2164 13.2699V16.1676H59.2389L59.2389 11.2144Z" fill="white"/>
<path d="M65.0031 9.28076H65.9807V16.1676H65.0031V9.28076Z" fill="white"/>
<path d="M67.3396 13.6889C67.3085 13.3634 67.3457 13.0349 67.449 12.7246C67.5522 12.4143 67.7192 12.129 67.9391 11.8871C68.1591 11.6451 68.4272 11.4517 68.7263 11.3194C69.0253 11.1871 69.3487 11.1188 69.6757 11.1188C70.0027 11.1188 70.3262 11.1871 70.6252 11.3194C70.9243 11.4517 71.1924 11.6451 71.4123 11.8871C71.6323 12.129 71.7992 12.4143 71.9025 12.7246C72.0057 13.0349 72.043 13.3634 72.0119 13.6889C72.0436 14.0147 72.0067 14.3436 71.9038 14.6544C71.8008 14.9652 71.6339 15.251 71.4139 15.4934C71.1939 15.7359 70.9256 15.9296 70.6262 16.0622C70.3269 16.1948 70.0031 16.2633 69.6757 16.2633C69.3483 16.2633 69.0246 16.1948 68.7252 16.0622C68.4259 15.9296 68.1576 15.7359 67.9376 15.4934C67.7175 15.251 67.5507 14.9652 67.4477 14.6544C67.3447 14.3436 67.3079 14.0147 67.3396 13.6889ZM71.0059 13.6889C71.0059 12.6152 70.5235 11.9873 69.6771 11.9873C68.8274 11.9873 68.3494 12.6152 68.3494 13.6889C68.3494 14.7712 68.8274 15.3942 69.6771 15.3942C70.5236 15.3942 71.0059 14.7669 71.0059 13.6889H71.0059Z" fill="white"/>
<path d="M73.041 14.7668C73.041 13.8752 73.7049 13.3612 74.8833 13.2882L76.225 13.2108V12.7833C76.225 12.2601 75.8791 11.9647 75.2109 11.9647C74.6652 11.9647 74.2871 12.1651 74.1786 12.5153H73.2322C73.3321 11.6645 74.1324 11.1188 75.256 11.1188C76.4978 11.1188 77.1982 11.737 77.1982 12.7833V16.1676H76.2572V15.4715H76.1799C76.0229 15.7212 75.8024 15.9248 75.541 16.0614C75.2797 16.198 74.9867 16.2628 74.6921 16.2492C74.4841 16.2708 74.274 16.2487 74.0751 16.1841C73.8763 16.1195 73.6932 16.014 73.5376 15.8743C73.3821 15.7347 73.2575 15.5639 73.172 15.3732C73.0864 15.1824 73.0418 14.9759 73.041 14.7668ZM76.225 14.3436V13.9295L75.0154 14.0068C74.3333 14.0525 74.0239 14.2845 74.0239 14.7212C74.0239 15.1669 74.4106 15.4264 74.9424 15.4264C75.0982 15.4421 75.2556 15.4264 75.4052 15.3801C75.5548 15.3338 75.6936 15.2579 75.8132 15.1569C75.9329 15.0558 76.031 14.9318 76.1017 14.7921C76.1725 14.6523 76.2144 14.4998 76.225 14.3436Z" fill="white"/>
<path d="M78.483 13.6888C78.483 12.1237 79.2876 11.1322 80.539 11.1322C80.8486 11.1179 81.1558 11.1921 81.4248 11.346C81.6938 11.4998 81.9134 11.7271 82.058 12.0012H82.131V9.28076H83.1086V16.1676H82.1718V15.385H82.0945C81.9387 15.6572 81.7115 15.8817 81.4373 16.034C81.1631 16.1863 80.8525 16.2608 80.539 16.2492C79.279 16.2493 78.483 15.2577 78.483 13.6888ZM79.4928 13.6888C79.4928 14.7394 79.988 15.3716 80.8162 15.3716C81.6401 15.3716 82.1493 14.7303 82.1493 13.6931C82.1493 12.6608 81.6348 12.0104 80.8162 12.0104C79.9933 12.0104 79.4927 12.6468 79.4927 13.6888H79.4928Z" fill="white"/>
<path d="M87.153 13.6888C87.1218 13.3633 87.1591 13.0349 87.2623 12.7246C87.3655 12.4143 87.5325 12.129 87.7524 11.887C87.9723 11.6451 88.2404 11.4517 88.5394 11.3194C88.8385 11.1871 89.1619 11.1188 89.4889 11.1188C89.8159 11.1188 90.1393 11.1871 90.4383 11.3194C90.7373 11.4517 91.0054 11.6451 91.2253 11.887C91.4453 12.129 91.6122 12.4143 91.7154 12.7246C91.8187 13.0349 91.8559 13.3633 91.8248 13.6888C91.8565 14.0146 91.8197 14.3435 91.7167 14.6543C91.6138 14.9651 91.447 15.2509 91.227 15.4933C91.007 15.7358 90.7387 15.9295 90.4394 16.0621C90.14 16.1947 89.8163 16.2632 89.4889 16.2632C89.1615 16.2632 88.8377 16.1947 88.5384 16.0621C88.239 15.9295 87.9708 15.7358 87.7508 15.4933C87.5308 15.2509 87.3639 14.9651 87.261 14.6543C87.158 14.3435 87.1213 14.0146 87.153 13.6888ZM90.8193 13.6888C90.8193 12.6151 90.337 11.9872 89.4905 11.9872C88.6408 11.9872 88.1628 12.6151 88.1628 13.6888C88.1628 14.7711 88.6408 15.3941 89.4905 15.3941C90.337 15.3941 90.8193 14.7668 90.8193 13.6888Z" fill="white"/>
<path d="M93.1364 11.2144H94.0774V12.0012H94.1505C94.2744 11.7186 94.4834 11.4817 94.7483 11.3235C95.0133 11.1653 95.321 11.0938 95.6286 11.1188C95.8696 11.1006 96.1116 11.137 96.3366 11.2251C96.5617 11.3132 96.7641 11.4508 96.9287 11.6277C97.0934 11.8047 97.2161 12.0164 97.2878 12.2472C97.3595 12.478 97.3784 12.722 97.343 12.961V16.1675H96.3655V13.2065C96.3655 12.4105 96.0196 12.0147 95.2966 12.0147C95.133 12.0071 94.9697 12.0349 94.8178 12.0963C94.6659 12.1577 94.5291 12.2513 94.4168 12.3705C94.3045 12.4897 94.2192 12.6318 94.167 12.787C94.1147 12.9423 94.0966 13.107 94.1139 13.2699V16.1676H93.1364V11.2144Z" fill="white"/>
<path d="M102.867 9.9812V11.237H103.94V12.0604H102.867V14.6073C102.867 15.1262 103.08 15.3534 103.567 15.3534C103.692 15.353 103.816 15.3454 103.94 15.3308V16.1451C103.764 16.1765 103.586 16.1932 103.408 16.195C102.321 16.195 101.888 15.8126 101.888 14.8576V12.0603H101.102V11.2369H101.888V9.9812H102.867Z" fill="white"/>
<path d="M105.275 9.28076H106.244V12.0104H106.321C106.451 11.7251 106.666 11.4869 106.936 11.3281C107.207 11.1693 107.519 11.0977 107.832 11.1231C108.071 11.11 108.311 11.15 108.534 11.2403C108.756 11.3305 108.956 11.4688 109.119 11.6451C109.282 11.8215 109.404 12.0317 109.476 12.2606C109.549 12.4896 109.569 12.7317 109.538 12.9696V16.1676H108.559V13.2108C108.559 12.4196 108.19 12.0189 107.5 12.0189C107.332 12.0052 107.163 12.0283 107.005 12.0866C106.846 12.145 106.703 12.2372 106.584 12.3568C106.465 12.4764 106.374 12.6205 106.317 12.7791C106.26 12.9376 106.238 13.1068 106.253 13.2747V16.1676H105.275L105.275 9.28076Z" fill="white"/>
<path d="M115.237 14.8302C115.105 15.2828 114.817 15.6744 114.424 15.9364C114.032 16.1984 113.56 16.314 113.091 16.2632C112.765 16.2718 112.44 16.2093 112.141 16.08C111.841 15.9507 111.573 15.7577 111.355 15.5145C111.138 15.2712 110.975 14.9835 110.88 14.6713C110.785 14.3591 110.758 14.0299 110.803 13.7065C110.76 13.3822 110.786 13.0522 110.881 12.7391C110.977 12.426 111.138 12.1369 111.354 11.8915C111.571 11.6461 111.838 11.4502 112.137 11.3168C112.435 11.1835 112.76 11.1159 113.087 11.1187C114.465 11.1187 115.296 12.0603 115.296 13.6157V13.9568H111.799V14.0116C111.783 14.1934 111.806 14.3763 111.866 14.5488C111.925 14.7212 112.02 14.8793 112.144 15.0129C112.269 15.1465 112.419 15.2527 112.587 15.3246C112.755 15.3965 112.936 15.4326 113.118 15.4306C113.352 15.4587 113.589 15.4166 113.798 15.3097C114.008 15.2027 114.182 15.0358 114.296 14.8301L115.237 14.8302ZM111.799 13.2339H114.301C114.313 13.0676 114.29 12.9006 114.234 12.7437C114.178 12.5867 114.09 12.4431 113.975 12.3223C113.86 12.2015 113.721 12.106 113.568 12.042C113.414 11.978 113.248 11.9469 113.081 11.9507C112.912 11.9486 112.745 11.9803 112.588 12.0441C112.431 12.1078 112.289 12.2023 112.17 12.3218C112.05 12.4414 111.956 12.5837 111.892 12.7404C111.828 12.897 111.797 13.0648 111.799 13.2339H111.799Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_781_326">
<rect width="131.63" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
}


import React from 'react';
import './App.css';
import { NavigationBar } from './components/NavigationBar';
import { Routes ,Route } from 'react-router-dom';
import { Footer } from "./components/Footer";
import { AboutPage } from './pages/About';
import { Home } from './pages/Home';
import { Register } from './Auth/Register';
import { Verification } from './pages/Verification';
import { UserConfirmed } from './pages/UserConfirmed';

function App() {
  return (
    <div className="App">
      <NavigationBar/>
      <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/about" element={<AboutPage/>} />
       <Route path='/register' element={<Register/>} />
       <Route path='/register/userConfirmed' element={<UserConfirmed/>} />
       <Route path='/EmailVerify' element={<Verification/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;

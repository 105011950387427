import { Box } from "@mui/material"
import { Footer } from "../../components/Footer"
import { NavigationBar } from "../../components/NavigationBar"
import { useEffect, useRef } from "react";
import { Link } from 'react-scroll';


export const AboutPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, []);


    return (
        <div style={{ margin: '30px 30px 30px 30px', }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Link to="" smooth={false} duration={0} offset={0}>
                </Link>
                <Box
                    sx={{
                        width: 750,
                        height: "auto",
                        maxWidth: '100%'
                    }}
                >
                    <h1 style={{ textAlign: 'left', fontWeight: 700 }} >BamPos Hakkında</h1>

                    <div style={{ textAlign: 'left', fontSize: '20px', color: '#595959' }}>
                        <p>
                            You open the fridge any day, and there they are, the sour milk, the expired yogurt, bad looking leftovers, or the rotten lemon. The incredible thing is that about 1/3 of the world's food production is lost or wasted—1.3 billion tons per year¹, around 170 and 180 kilos per person. The output of 30% of the planet's agricultural land (that's greater than the size of China!), along with 170 billion cubic meters of water², all wasted.
                        </p>
                        <p>
                            Once all this food breaks down, it produces a carbon footprint of 4.4 billion tons of CO2 each year. Between 8 and 10% of the global greenhouse emissions³. The third-largest greenhouse gas emitter in the world just after China and the USA.
                        </p>
                        <p>
                            40% of all this waste is generated at home³—expired produce and discarded or gone bad leftovers that we throw away because we have cooked too much. The annual cost for each family can be a surprising amount between $1,200 and $1,800 US dollars³, depending on where you live on the planet.
                        </p>
                        <p>
                            We build Kiff with one goal in mind: help you eat your food in its best state possible. Because food loses its nutritional capabilities after keeping it for too long, and if spoiled, it can be a risk for your health. By eating fresh food, you waste less, save money, and help the planet.
                        </p>
                    </div>
                </Box>
            </div>
        </div>

    )
}
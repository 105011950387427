import { Box, Card, CardContent, Typography } from '@mui/material'
import ErpMacImage from '../../Assets/images/mockup.png'
import { ComputerIcon } from '../../Assets/icons/ComputerIcon'

export const ScrollERP = () => {
    return (
        <>
            <Box sx={{ backgroundColor: '#FCF7FF', width: '100%', minHeight: '100vh', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
                <div style={{ position: 'relative', flex: 1 }}>
                    <img src={ErpMacImage} alt="Mockup" style={{ height: 'auto' }} />
                </div>
                <div className="mobile-view content-wrapper" style={{ position: 'relative', flex: 1, padding: '0 20px', order: 1 }}>
                    <Box sx={{
                        backgroundColor: 'transparent',
                        width: {
                            xs: 100,
                            sm: 200,
                            md: 300,
                            lg: 400,
                            xl: 500
                        }
                    }}
                    >
                        <Typography variant="h4" component="div" align='left' fontWeight='bold'>
                            <ComputerIcon />
                        </Typography>
                        <Typography variant="h4" component="div" align='left' fontWeight='bold' marginBottom={3}>
                            BamPos ERP Modülü
                        </Typography>
                        <Typography variant="h5" component="div" align='left' color='#595959'>
                            BamPos ERP Modülü tüm veri giriş-çıkışının yapıldığı baştan uca bir perakende yönetim sistemidir.
                        </Typography>

                    </Box>
                </div>
            </Box>

            <Box sx={{ backgroundColor: '#FCF7FF', width: '100%', minHeight: '100vh', justifyContent: 'center', alignItems: 'center', flexDirection:'column', display: { xs: 'flex', md: 'none' } }}>
                <div style={{ position: 'relative',  order:1,}}>
                    <img src={ErpMacImage} alt="Mockup" style={{ height:"auto",  maxWidth: '90%' }} />
                </div>
                <div className="mobile-view content-wrapper" style={{ position: 'relative', flex:0.5,padding: '10px 20px', order: 0,  height:"auto",  maxWidth: '90%' }}>
                    <Box sx={{
                        backgroundColor: 'transparent',
                        width: {
                            xs: 300,
                            sm: 300,
                            md: 400,
                            lg: 500,
                            xl: 600
                        },
                        height:"auto",  maxWidth: '100%',
                    }}
                    >
                        <Typography variant="h4" component="div" align='center' fontWeight='bold'>
                            <ComputerIcon />
                        </Typography>
                        <Typography variant="h4" component="div" align='center' fontWeight='bold' marginBottom={3}>
                            BamPos ERP Modülü
                        </Typography>
                        <Typography variant="h5" component="div" align='center' color='#595959'>
                            BamPos ERP Modülü tüm veri giriş-çıkışının yapıldığı baştan uca bir perakende yönetim sistemidir.
                        </Typography>

                    </Box>
                </div>
            </Box>
        </>
    )
}


import * as React from 'react';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { UserRegistrationsApiFactory } from '../../Services/userAccess-sdk';
import { apiConfigFactory } from '../../config';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import LinearProgress from '@mui/material/LinearProgress';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

const userService = UserRegistrationsApiFactory(apiConfigFactory)
export const Verification = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const controller = new AbortController()
        userService.userAccessUserRegistrationsUserRegistrationIdConfirmPatch(searchParams.get("userRegistrationId")!,{signal:controller.signal})
        .then(res => {
         if(res.status === 200) {
             setStatus(true)
             setTimeout(() => {
                setIsLoading(false)
             },2000)
             
         }
        })
        .catch(err => {
           setStatus(false)
           setTimeout(() => {
            setIsLoading(false)
         },2000)
        })
        return () => controller.abort()

    },[]) 


    if(isLoading) {
        return <div style={{alignItems:'center', justifyContent:'center', display:'flex', marginTop:100}}>
            <Box
            sx={{
              width: 500,
              height: 280,
              marginBottom:11,
              padding:8,
            }}
            >
                <div>
                <PauseCircleOutlineIcon
                    style={{fontSize:80, color:'#1976d2'}}          
                />
                <p style={{fontWeight:'bold', fontSize:35, padding:0, margin:0, marginTop:25}}>Hesap Onaylanıyor...</p>
                <Box sx={{ width: '75%',marginTop:5, marginLeft:8}}>
                    <LinearProgress 

                    />
                </Box>
                </div>
            </Box>
        </div>;
    }
        
    return (
        <div style={{alignItems:'center', justifyContent:'center', display:'flex', marginTop:100}}>
        {status ? (
            <Box
            sx={{
              width: 500,
              height: 280,
              marginBottom:11,
              padding:8,
            }}
            >
                <div>
                <CheckCircleOutlineIcon 
                    color='success'
                    style={{fontSize:80}}
                />
                <h1>Kullanıcı Doğrulaması Başarılı!</h1>
                <p style={{marginBottom:50, fontSize:20}}>BamPos ERP Üzerinden Giriş Yapmak İçin <br/> Aşağıdaki Linke Tıklayınız</p>
                <a href='https://erp-test.bampos.com/#/login' style={{fontWeight:'bold', fontSize:25, color:'green',textDecoration:'none' }}>Giriş Yap</a>
                </div>
            </Box>
        ) :  

            <Box
            sx={{
            width: 500,
            height: 280,
            marginBottom:11,
            padding:8,
            }}
            >
                <div>
                <ErrorIcon 
                    style={{fontSize:80}}
                    color='error'
                />
                <h1>Hay Aksi! Bir Hata Oluştu.</h1>
                <p style={{marginBottom:50, fontSize:20}}>Yukarıdaki Linkten Kayıt Olabilir, Hesabınız Varsa Aşağıdaki Linkten Giriş Yapabilirsiniz.</p>
                <a href='https://erp-test.bampos.com/#/login' style={{fontWeight:'bold', fontSize:25, color:'green',textDecoration:'none' }}>Giriş Yap</a>
                </div>

            </Box>


            }
        </div>
      );
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { BamPosNavBarIcon } from '../../Assets/icons/BampPosNavBar';
import { useEffect } from 'react';




function ElevationScroll(props:any) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const navItems = ['ERP', 'Kasiyer', 'Entegrasyonlar'];

export const NavigationBar = () => {

    const [selectedItem, setSelectedItem] = React.useState(0)

    const handleNavItemsColor = (index: any) => {
        setSelectedItem(index)
    }

    const scrollToTop = (top:any) => {
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    };

    useEffect(() => {
      const handleScroll = () => {
        const currentPosition = window.pageYOffset;
        const newIndex = calculateSelectedItem(currentPosition);
        setSelectedItem(newIndex);
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const calculateSelectedItem = (currentPosition:any) => {
      if (currentPosition < 800) {
        return 0;
      } else if (currentPosition < 1500) {
        return 1;
      } else {
        return 2;
      }
    };

  return (
    <React.Fragment>
    

            <AppBar position='fixed' color="inherit" elevation={0}>

                 <Container maxWidth="xl" >
                     <Toolbar disableGutters>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                             <Link to='/' style={{padding:0, margin:0}}>
                                <BamPosNavBarIcon />
                              </Link>
                            
                     </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent:"center", alignItems:"center", }}>
                            <Link to='/' style={{}}>
                              <BamPosNavBarIcon />
                            </Link>
                       </Box>
                       <Box  sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <Link
                             to='/register'
                              style={{
                                 color: '#2E56A3',
                                fontWeight: "bold",
                                boxShadow: 'none',
                             }}
                            
                           
                             >
                                 Kayıt Ol
                             </Link> 
                         </Box>
                         <Box sx={{ flexGrow: 0.04, display: { xs: 'none', md: 'flex' } }}>
                           {navItems.map((item, index) => (

                                <Button key={item}
                                     sx={{
                                        color: selectedItem === index ? '#00D1FF' : '#000000',
                                         fontWeight: "bold",
                                         boxShadow: 'none',
                                        '&:hover': {
                                             boxShadow: 'none',
                                         },
                                        '&:active': {
                                             boxShadow: 'none',
                                         },
                                     }}
                                     onChange={() => handleNavItemsColor(index)}
                                     onClick={() => {
                                      handleNavItemsColor(index)
                                      index === 0 ?
                                      scrollToTop(0)
                                      : index === 1 ?
                                      scrollToTop(1020)
                                      :
                                      scrollToTop(2000)
                                    }}
                                     disableRipple
                                 >
                                    {item}
                                 </Button>
                             ))}
                         </Box>
                         <Box  sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                            <Link
                             to='/register'
                              style={{
                                 color: '#2E56A3',
                                fontWeight: "bold",
                                boxShadow: 'none',
                             }}
                             >
                                 KAYDOL
                             </Link> 
                         </Box>
                     </Toolbar>
                 </Container>
             </AppBar>
      <Toolbar />
    </React.Fragment>
  );
}


































// import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import Button from '@mui/material/Button';
// import { BamPosNavBarIcon } from '../../Assets/icons/BampPosNavBar';
// import ErpMacImage from '../../Assets/images/mockup.png'
// import ErpiPhoneImage from '../../Assets/images/mockupMobile.png'
// import { ComputerIcon } from '../../Assets/icons/ComputerIcon';
// import { Card, CardContent, CssBaseline } from '@mui/material';
// import BamPosCardImage from '../../Assets/images/bampos-card.png'
// import { AppStoreIcon } from '../../Assets/icons/AppStoreIcon';
// import { IntegrationIcon } from '../../Assets/icons/IntegrationIcon';
// import { makeStyles, TextField, Grid } from '@material-ui/core';
// import { IntegrationCard } from '../Card';
// import { Footer } from '../Footer';
// import { GooglePlayIcon } from '../../Assets/icons/GooglePlayIcon';
// import { Register } from '../../Auth/Register';
// import { Link } from 'react-router-dom';

// const navItems = ['ERP', 'Kasiyer', 'Entegrasyonlar'];

// const useStyles = makeStyles((theme) => ({
//     container: {
//         marginLeft: theme.spacing(25),
//         marginRight: theme.spacing(25),
//         marginTop: theme.spacing(4),
//     },
//     reportDiv: {
//         background: "#FFFFFF",
//         border: "1px solid #ECECEC",
//         boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.1)",
//         borderRadius: 10,
//         padding: 20
//     },
//     valueSpan: {
//         fontWeight: 700,
//         fontSize: 30
//     },
//     titleSpan: {
//         fontWeight: 500,
//         fontSize: 18,
//         marginLeft: 15,
//     },
//     reportBtn: {
//         borderRadius: 7,
//         padding: "5px 20px",
//         fontSize: 14,
//     },
//     titleDiv: {
//         display: 'flex',
//         alignItems: 'center'
//     }
// }));





// export const NavigationBar = () => {
//     const [selectedItem, setSelectedItem] = React.useState(0)
//     const classes = useStyles()

//     const handleNavItemsColor = (index: any) => {
//         setSelectedItem(index)
//     }



//     return (
//         <>
//             <AppBar color="inherit" elevation={0} >

//                 <Container maxWidth="xl">
//                     <Toolbar disableGutters>
//                         <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
//                             <Link to='/' style={{padding:0, margin:0}}>
//                             <BamPosNavBarIcon />
//                             </Link>
                            
//                         </Box>
//                         <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, }}>
//                             <BamPosNavBarIcon />
//                         </Box>
//                         <Box sx={{ flexGrow: 0.04, display: { xs: 'none', md: 'flex' } }}>
//                             {navItems.map((item, index) => (

//                                 <Button key={item}
//                                     sx={{
//                                         color: selectedItem === index ? '#00D1FF' : '#000000',
//                                         fontWeight: "bold",
//                                         boxShadow: 'none',
//                                         '&:hover': {
//                                             boxShadow: 'none',
//                                         },
//                                         '&:active': {
//                                             boxShadow: 'none',
//                                         },
//                                     }}
//                                     onClick={() => handleNavItemsColor(index)}
//                                     disableRipple
//                                 >
//                                     {item}
//                                 </Button>
//                             ))}
//                         </Box>
//                         <Box  sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
//                             <Link
//                              to='/register'
//                              style={{
//                                 color: '#17D362',
//                                 fontWeight: "bold",
//                                 boxShadow: 'none',
//                             }}
                            
                           
//                             >
//                                 Kayıt Ol
//                             </Link> 
//                         </Box>
//                     </Toolbar>
//                 </Container>
//             </AppBar>
//         </>
//     );
// }
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { MenuItem } from '@mui/material';
import { ProfileImageIcon } from '../../Assets/icons/ProfileImageIcon'
import { useCallback, useState } from 'react';
import { UserRegistrationsApiFactory, RegisterNewUserRequest } from '../../Services/userAccess-sdk'
import { apiConfigFactory } from '../../config';
import { useForm, Controller } from 'react-hook-form';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

const userService = UserRegistrationsApiFactory(apiConfigFactory)


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 500,
    marginTop: 130
  },
  formBox: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: "12px 0 12px 0"
  },
}));




export const Register = ({ setIsRegistering }: any) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const defaultTheme = createTheme();
  const { register, control, errors } = useForm();
  const [isLoading, setIsLoading] = React.useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordAgain, setPasswordAgain] = React.useState('')

  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = React.useState(true)
  const [isEmailValid, setIsEmailValid] = React.useState(true)
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [isPasswordAgainValid, setIsPasswordAgainValid] = useState(true)
  const [focus, setFocus] = useState<any>()

  const [isUsernameCheckValid, setIsUsernameCheckValid] = React.useState(true)
  const [isEmailCheckValid, setIsEmailCheckValid] = React.useState(true)
  const [isPasswordMatchValid, setIsPasswordMatchValid] = useState(true)



  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickAgainShowPassword = () => setShowPasswordAgain((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => { event.preventDefault(); };


  function registration() {
    setIsUsernameCheckValid(true)
    setIsEmailCheckValid(true)
    userService
      .userAccessUserRegistrationsPost({
        login: userName!,
        firstName: firstName!,
        lastName: lastName!,
        email: email!,
        password: password!,
        confirmLink: window.location.origin + '/EmailVerify?userRegistrationId='
      })
      .then(data => {
        if (data.status === 200) {
          setTimeout(() => {
            navigate({
              pathname:'/register/userConfirmed',
              search:createSearchParams({
                email:email
              }).toString()
            })
          },2000)

        }

      })
      .catch(err => {
        const errorCodes = err.response.data.errors;
        if (errorCodes.includes("E246")) {
          setIsEmailCheckValid(false)
          setIsLoading(false)
        } else if (errorCodes.includes("E247")) {
          setIsUsernameCheckValid(false)
          setIsLoading(false)
        }

      })

  }


  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!isFirstNameValid ||
      !isLastNameValid ||
      !isUsernameCheckValid ||
      !isEmailCheckValid ||
      !isUsernameValid ||
      !isEmailValid ||
      !isPasswordValid ||
      !isPasswordAgainValid ||
      !isPasswordMatchValid
      
    ) {
      return;
    }
    setIsLoading(true)
    registration();

  };



  const validateFirstName = () => {
    if (!firstName || firstName.length < 3) {
      setIsFirstNameValid(false);
      return;
    } else {
      setIsFirstNameValid(true);
    }
  };
  const validateLastName = () => {
    if (!lastName || lastName.length < 2) {
      setIsLastNameValid(false);
      return;
    } else {
      setIsLastNameValid(true);
    }
  }

  const validateUserName = () => {
    if (!userName || userName.length < 3) {
      setIsUsernameValid(false);
    } else {
      setIsUsernameValid(true);
    }
  };
  const validateEmail = () => {
    if (!email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setIsEmailValid(false)

    } else {
      setIsEmailValid(true)
    }
  }

  const validatePassword = () => {
    if (!password || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(password)) {
      setIsPasswordValid(false)
    } else {
      setIsPasswordValid(true)
    }
  }
  const syncPassword = () => {
    if (password !== passwordAgain) {
      setIsPasswordMatchValid(false);
    } else {
      setIsPasswordMatchValid(true);
    }
  };
  
  const handlePasswordChange = (newValue:any) => {
    setPassword(newValue);
    setIsPasswordValid(newValue.length >= 6 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(newValue));
    if (passwordAgain) {
      setIsPasswordMatchValid(passwordAgain === newValue);
    }
  };
  
  
  
  const handlePasswordAgainChange = (newValue:any) => {
    setPasswordAgain(newValue);
    setIsPasswordValid(password.length >= 6 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(password));
    setIsPasswordMatchValid(password === newValue);
  };

  // const validatePasswordAgain = () => {
  //   if(!passwordAgain){
  //     setIsPasswordAgainValid(false)
  //   }else {
  //     setIsPasswordAgainValid(true)
  //   }
  // }





  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md" className={classes.container} sx={{ display: 'flex', marginBottom: 10 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: 3 }} >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6} >
                  <TextField
                    required
                    fullWidth
                    type='text'
                    name='firstName'
                    label='Ad'
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setIsFirstNameValid(true);
                    }}
                    onFocus={(e) => setFocus(e.target.name)}
                    inputProps={{
                      pattern: '^[A-Za-zÇçĞğİıÖöŞşÜü ]+$',
                    }}
                    FormHelperTextProps={{
                      error: !isFirstNameValid,
                    }}
                    helperText={
                      (!isFirstNameValid) &&
                      'Lütfen Adınızı Giriniz'
                    }
                    onBlur={validateFirstName}
                    error={!isFirstNameValid}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    type='text'
                    name='lastName'
                    label='Soyad'
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setIsLastNameValid(true);
                    }}
                    onFocus={(e) => setFocus(e.target.name)}
                    inputProps={{
                      pattern: '^[A-Za-zÇçĞğİıÖöŞşÜü ]+$',
                      title: 'En az 2 harf içermelidir',
                    }}
                    FormHelperTextProps={{
                      error: !isLastNameValid,
                    }}
                    helperText={
                      (!isLastNameValid) &&
                      'Lütfen Soyadınızı Giriniz'
                    }
                    onBlur={validateLastName}
                    error={!isLastNameValid}

                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Kullanıcı Adı"
                    name="phoneNumber"
                    autoComplete="login"
                    onChange={(e) => { setUserName(e.target.value); setIsUsernameCheckValid(true); setIsUsernameValid(true); }}
                    value={userName}
                    sx={{ background: "#FFFFFF" }}
                    error={!isUsernameValid || !isUsernameCheckValid}
                    FormHelperTextProps={{
                      error: !isUsernameValid || !isUsernameCheckValid
                    }}
                    helperText={!isUsernameCheckValid ? 'Bu Kullanıcı Adı Daha Önceden Alınmış' : !isUsernameValid ? 'Lütfen Kullanıcı Adınızı Giriniz' : ''}
                    onBlur={validateUserName}
                    onFocus={(e) => setFocus(e.target.name)}

                  />
                  {/* {!isUsernameValid && (
                    <Typography variant="caption" color="error" textAlign='left'>
                      Bu kullanıcı adı daha önceden alınmış.
                    </Typography>
                  )} */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="E-Posta"
                    name="email"
                    autoComplete="login"
                    onChange={(e) => { setEmail(e.target.value); setIsEmailValid(true); setIsEmailCheckValid(true); }}
                    value={email}
                    sx={{ background: "#FFFFFF" }}
                    error={!isEmailValid || !isEmailCheckValid}
                    FormHelperTextProps={{
                      error: !isEmailValid || !isEmailCheckValid
                    }}
                    helperText={!isEmailCheckValid ? 'Mail Adresi ile Daha Önceden Kayıt Olunmuş ' : !isEmailValid ? 'Lütfen Mail Adresinizi Doğru Bir Biçimde Giriniz' : ''}
                    onBlur={validateEmail}
                    onFocus={(e) => setFocus(e.target.name)}
                  />
                </Grid>


                <Grid item xs={12} sm={6}>

                  <FormControl fullWidth variant="outlined" sx={{ background: "#FFFFFF" }}>
                    <InputLabel htmlFor="outlined-adornment-password">Şifre</InputLabel>
                    <OutlinedInput
                      required
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Şifre"
                      onChange={(e) => { setPassword(e.target.value); setIsPasswordValid(true); handlePasswordChange(e.target.value) }}
                      onBlur={validatePassword}
                      value={password}
                      error={!isPasswordValid}
                      
                    />
                    {!isPasswordValid && (
                      <FormHelperText error={!isPasswordValid}>
                        Şifre En Az 6 Karakter Uzunluğunda Olmalıdır ve En Az Bir Büyük Harf, Bir Küçük Harf ve Bir Rakam İçermelidir.
                      </FormHelperText>
                    )}

                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined" sx={{ background: "#FFFFFF" }}>
                    <InputLabel htmlFor="outlined-adornment-password">Şifre Tekrar</InputLabel>
                    <OutlinedInput
                      required
                      id="outlined-adornment-password"
                      type={showPasswordAgain ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickAgainShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordAgain ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Şifre Tekrar"
                      onChange={(e) => { setPasswordAgain(e.target.value); setIsPasswordMatchValid(true); setIsPasswordValid(true); handlePasswordAgainChange(e.target.value) }}
                      onBlur={syncPassword}
                      value={passwordAgain}
                      error={!isPasswordMatchValid}
                      
                    />

                    {!isPasswordMatchValid && (
                      <FormHelperText error={!isPasswordMatchValid}>
                        Şifreler Eşleşmedi. Lütfen Kontrol Ediniz
                      </FormHelperText>
                    )}

                  </FormControl>


                </Grid>
                {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  
                  label= "KVKK Bilgilendirme Metnini Okudum ve Kabul Ediyorum"
                />
              </Grid> */}
              </Grid>
              <Grid item xs={12} sm={6} textAlign="center">

                <Button
                  type="submit"
                  
                  variant='contained'
                  sx={{ mt: 3, mb: 2, padding: "12px 80px 12px 80px", top: 30, boxShadow: 'none' , backgroundColor:isLoading ?'#FFFFFF' : '#2E56A3', }}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24}  /> : 'Kayıt Ol'}

                </Button>



              </Grid>
            </form>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  );
}
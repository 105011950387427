import { Box, Card, CardContent, IconButton, Typography } from '@mui/material'
import ErpiPhoneImage from '../../Assets/images/mockupMobile.png'
import BamPosCardImage from '../../Assets/images/bampos-card.png'
import { AppStoreIcon } from '../../Assets/icons/AppStoreIcon'
import { GooglePlayIcon } from '../../Assets/icons/GooglePlayIcon'

export const ScrollCashier = () => {
    return (
        <>
            <Box sx={{ backgroundColor: '#D6F2FF', width: 'auto', minHeight: '100vh', justifyContent: 'center', alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>

                <div style={{ position: 'static', flex: 1}}>
                    <Box sx={{
                        backgroundColor: 'transparent',
                        width: {
                            xs: 100,
                            sm: 200,
                            md: 300,
                            lg: 400,
                            xl: 400
                        },
                        marginLeft:50
                        
                    }}>
                        <Typography variant="h4" component="div" fontWeight='bold' align='left'>
                            <img src={BamPosCardImage} alt="BamPos" style={{ width: 'auto', height: 'auto' }} />
                        </Typography>
                        <Typography variant="h4" component="div" align='left' fontWeight='bold' marginBottom={3}>
                            BamPos Kasiyer Uygulaması
                        </Typography>
                        <Typography variant="h5" component="div" align='left' color='#595959'>
                            BamPos Kasiyer Uygulaması hem web sistemleri hem de mobilde çalışabilen bir hızlı kasa uygulamasıdır.
                        </Typography>

                        <Typography component="div" align='left' marginTop={3}>
                            <IconButton style={{ padding: 0, boxShadow: 'none', marginRight: 15 }}>
                                <AppStoreIcon />
                            </IconButton>
                            <IconButton style={{ padding: 0, boxShadow: 'none' }}>
                                <GooglePlayIcon />
                            </IconButton>
                        </Typography>
                    </Box>

                </div>
                <div style={{ position: 'relative', flex: 1, marginTop:350, display:'flex', marginLeft:100 }}>
                    <img src={ErpiPhoneImage} alt="Mockup" style={{ height:"auto",  maxWidth: '100%' }} />
                </div>


            </Box>

            <Box sx={{ backgroundColor: '#D6F2FF', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '100vh', display: { xs: 'flex', md: 'none' } }}>
                <div style={{ position: 'relative', flex: 0.5, marginTop: '50px', order: 0, height: "auto", maxWidth: '100%', padding: '10px 20px' }}>
                    <Box sx={{
                        backgroundColor: 'transparent',
                        width: {
                            xs: 300,
                            sm: 300,
                            md: 400,
                            lg: 500,
                            xl: 600
                        },
                        height: "auto", maxWidth: '100%',
                    }}>

                        <Typography variant="h4" component="div" fontWeight='bold' align='center' marginBottom={3}>
                            <img src={BamPosCardImage} />
                        </Typography>
                        <Typography variant="h4" component="div" align='center' fontWeight='bold' marginBottom={3}>
                            BamPos Kasiyer Uygulaması
                        </Typography>
                        <Typography variant="h5" component="div" align='center' color='#595959'>
                            BamPos Kasiyer Uygulaması hem web sistemleri hem de mobilde çalışabilen bir hızlı kasa uygulamasıdır.
                        </Typography>

                        <Box component="div" display='flex' marginTop={3} >
                            <IconButton style={{ boxShadow: 'none' }}>
                                <AppStoreIcon />
                            </IconButton>
                            <IconButton style={{ boxShadow: 'none' }}>
                                <GooglePlayIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </div>
                <div style={{ position: 'relative', order: 1 }}>
                    <img src={ErpiPhoneImage} alt="Mockup" style={{ height: "auto", maxWidth: '80%', marginTop: 50 }} />
                </div>


            </Box>
        </>
    )
}

import { IntegrationIcon } from "../../Assets/icons/IntegrationIcon"
import { IntegrationCard } from "../Card"
import { Footer } from "../Footer"


export const ScrollIntegration = () => {

    return (
        <div style={{ backgroundColor: '#FFFFFF', width: '100%', }}>
        <div style={{ position: 'relative', top: 50 }}>
            <IntegrationIcon />
            <h1>Entegrasyonlar</h1>
        </div>
        <div>
        <IntegrationCard/>
        </div>
    </div>
    )
}